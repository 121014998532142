// Contact.js
import React, { useState, useEffect } from 'react';
import { Typography, Container, Box, TextField, Button, createTheme, ThemeProvider } from '@mui/material';
import { sendContactForm } from './apiService';

function Contact() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const name = data.get("name");
    const email = data.get("email");
    const message = data.get("message");
    try {
      const response = await sendContactForm(name, email, message);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#ffffff' : '#000000',
      },
      secondary: {
        main: darkMode ? '#aaaaaa' : '#333333',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container>
      <Box component="form" onSubmit={handleSubmit} mt={2}>
          <Typography variant="h4" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            Have questions or feedback? We'd love to hear from you. Fill out the form below and we'll get back to you as soon as possible.
          </Typography>
            <TextField
              fullWidth
              label="Name"
              placeholder="Your name"
              margin="normal"
              variant="outlined"
              name="name"
            />
            <TextField
              fullWidth
              label="Email"
              placeholder="Your email address"
              margin="normal"
              variant="outlined"
              name="email"
            />
            <TextField
              fullWidth
              label="Message"
              placeholder="Your message"
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              name="message"
            />
            <Button variant="contained" color={darkMode ? "secondary" : "primary"} type="submit">
              Send Message
            </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Contact;
