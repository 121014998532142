import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Paper,
  createTheme,
  ThemeProvider,
  Switch,
  FormControlLabel,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

function InfoPage() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#bb86fc' : '#6200ee',
      },
      secondary: {
        main: darkMode ? '#03dac6' : '#03dac6',
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h3" gutterBottom>
            BlockWebAI Guide
          </Typography>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
            label={darkMode ? 'Dark Mode' : 'Light Mode'}
          />
        </Box>

        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Welcome to BlockWebAI
          </Typography>
          <Typography variant="body1" paragraph>
            BlockWebAI is your intelligent companion for a focused and safe web experience. Let's explore how it works and how you can make the most of it.
          </Typography>
        </Paper>

        <Grid container spacing={4}>
          {/* Profiles Section */}
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Profiles</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  BlockWebAI allows you to create individual profiles for each user, such as your children. Profiles help you tailor the browsing experience to each user's needs.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Creating and Managing Profiles
                </Typography>
                <Typography variant="body1" paragraph>
                  - You can create new profiles directly in the BlockWebAI extension or through the user interface.
                </Typography>
                <Typography variant="body1" paragraph>
                  - Each profile can have its own specific prompts and settings.
                </Typography>
                <Typography variant="body1" paragraph>
                  - Switch between profiles easily within the extension to manage different users.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Prompts Section */}
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Prompts</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  Prompts are central to how BlockWebAI filters content. You can set prompts to guide the AI in evaluating webpages, ensuring they align with desired topics or safety standards.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Universal Prompts
                </Typography>
                <Typography variant="body1" paragraph>
                  - Universal prompts apply to all profiles and users.
                </Typography>
                <Typography variant="body1" paragraph>
                  - Use universal prompts to enforce general guidelines, such as "Ensure all content is age-appropriate and safe."
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Profile-Specific Prompts
                </Typography>
                <Typography variant="body1" paragraph>
                  - Set individual prompts for each profile to cater to specific needs.
                </Typography>
                <Typography variant="body1" paragraph>
                  - For example, if one child needs to focus on math homework, set a prompt like "Focus on math study materials and resources."
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Combining Prompts
                </Typography>
                <Typography variant="body1" paragraph>
                  - BlockWebAI intelligently combines universal prompts with profile-specific prompts.
                </Typography>
                <Typography variant="body1" paragraph>
                  - This ensures that both general safety guidelines and individual focus areas are considered when filtering content.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Editing Prompts
                </Typography>
                <Typography variant="body1" paragraph>
                  - You can edit prompts by double-clicking on them within the extension or UI.
                </Typography>
                <Typography variant="body1" paragraph>
                  - Update prompts as needed to reflect changing requirements or focus areas.
                </Typography>
                <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1, mt: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    Example:
                    <br />
                    Universal Prompt: "Ensure all content is appropriate for children under 13."
                    <br />
                    Profile-Specific Prompt: "Focus on learning about the solar system for science class."
                    <br />
                    Combined Prompt: The AI will prioritize safe, child-friendly content related to the solar system.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Whitelists and Blacklists Section */}
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Whitelists and Blacklists</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" paragraph>
                  Customize your browsing experience further with whitelists and blacklists of URLs.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Whitelists
                </Typography>
                <Typography variant="body1" paragraph>
                  - URLs in the whitelist are always allowed, regardless of content.
                </Typography>
                <Typography variant="body1" paragraph>
                  - Use whitelists to permit trusted educational sites and resources.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Blacklists
                </Typography>
                <Typography variant="body1" paragraph>
                  - URLs in the blacklist are always blocked.
                </Typography>
                <Typography variant="body1" paragraph>
                  - Use blacklists to block known distracting or inappropriate sites.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Format
                </Typography>
                <Typography variant="body1" paragraph>
                  - Lists are in CSV format with one URL per line.
                </Typography>
                <Box sx={{ bgcolor: 'background.default', p: 2, borderRadius: 1, mt: 2 }}>
                  <Typography variant="body2" color="textSecondary">
                    Example:
                    <br />
                    https://www.education.com
                    <br />
                    https://www.khanacademy.org
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Note: Adding a URL includes all its subdomains.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, p: 3, bgcolor: 'secondary.main', color: 'secondary.contrastText', borderRadius: 2 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <LightbulbIcon sx={{ mr: 1 }} /> Pro Tips
          </Typography>
          <Typography variant="body1" paragraph>
            - Regularly update prompts and lists to adapt to your child's evolving needs.
          </Typography>
          <Typography variant="body1" paragraph>
            - Engage with your child to set goals and focus areas, enhancing the effectiveness of BlockWebAI.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default InfoPage;
