import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Paper,
  createTheme,
  ThemeProvider,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SetupGuide() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#bb86fc' : '#6200ee',
      },
      secondary: {
        main: darkMode ? '#03dac6' : '#03dac6',
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h3" gutterBottom>
            BlockWebAI Setup Guide for Parents
          </Typography>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
            label={darkMode ? 'Dark Mode' : 'Light Mode'}
          />
        </Box>

        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Ensuring a Safe Browsing Experience for Your Child
          </Typography>
          <Typography variant="body1" paragraph>
            Follow these comprehensive steps to set up BlockWebAI on your child's device. Currently, the auto-setup installer is available only for Windows. For macOS and iOS, please follow the manual setup instructions.
          </Typography>
        </Paper>

        {/* Windows Auto Setup Section */}
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Auto Setup on Windows</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              BlockWebAI offers an automatic setup installer for Windows devices. This installer will:
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Install the BlockWebAI extension on the chosen browser." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Disable private browsing modes." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Restrict the creation of alternate browser profiles." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Prevent uninstallation of the BlockWebAI extension." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Restrict access to other browsers and search engines." />
              </ListItem>
            </List>
            <Box textAlign="center" mt={2}>
              <Link href="/BlockWebAI_Setup.exe" underline="none">
                <Button variant="contained" color="primary" size="large">
                  Download BlockWebAI for Windows
                </Button>
              </Link>
            </Box>
            <Typography variant="body1" paragraph sx={{ mt: 3 }}>
              After downloading, run the installer and follow the on-screen instructions. Once installation is complete, open the chosen browser and sign into the BlockWebAI extension to start protecting your child online.
            </Typography>
            <Typography variant="body2" color="error">
              Note: Windows might flag the installer as unsafe. Please see the section below on how to resolve this issue.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Windows Manual Setup Section */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Manual Setup on Windows</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1" gutterBottom>
              Step 1: Install BlockWebAI Extension
            </Typography>
            <Typography variant="body1" paragraph>
              Open the browser (e.g., Chrome, Edge) on your child's Windows device and navigate to the BlockWebAI extension page. Click "Add to Chrome" or "Add to Edge" to install the extension.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 2: Disable Private Browsing
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>For Chrome:</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              - Press <kbd>Win + R</kbd>, type <code>regedit</code>, and press Enter to open the Registry Editor.
            </Typography>
            <Typography variant="body1" paragraph>
              - Navigate to <code>HKEY_LOCAL_MACHINE\SOFTWARE\Policies\Google\Chrome</code>. If the path doesn't exist, create it.
            </Typography>
            <Typography variant="body1" paragraph>
              - Create a new DWORD (32-bit) value named <code>IncognitoModeAvailability</code> and set its value to <code>1</code> to disable Incognito mode.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>For Edge:</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              - Navigate to <code>HKEY_LOCAL_MACHINE\SOFTWARE\Policies\Microsoft\Edge</code>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Create a new DWORD (32-bit) value named <code>InPrivateModeAvailability</code> and set its value to <code>1</code> to disable InPrivate browsing.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 3: Restrict Alternate Profiles
            </Typography>
            <Typography variant="body1" paragraph>
              - In Chrome, navigate to <code>chrome://settings</code> and ensure that profile creation is restricted.
            </Typography>
            <Typography variant="body1" paragraph>
              - Alternatively, use Group Policy Editor to enforce profile restrictions.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 4: Prevent Extension Uninstallation
            </Typography>
            <Typography variant="body1" paragraph>
              - In the Registry Editor, navigate to <code>HKEY_LOCAL_MACHINE\SOFTWARE\Policies\Google\Chrome\ExtensionInstallForcelist</code>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Create a new string value with the name as <code>1</code> and data as the BlockWebAI extension ID followed by the update URL. For example:
            </Typography>
            <Typography variant="body2" paragraph>
              <code>abcdefghijklmnopabcdefghijklmnop;https://clients2.google.com/service/update2/crx</code>
            </Typography>
            <Typography variant="body1" paragraph>
              - This forces the installation of the extension and prevents its removal.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 5: Restrict Other Browsers and Search Engines
            </Typography>
            <Typography variant="body1" paragraph>
              - Uninstall or restrict access to other browsers like Firefox.
            </Typography>
            <Typography variant="body1" paragraph>
              - Use parental control software or Windows Family Safety to block the installation of new software.
            </Typography>
            <Typography variant="body1" paragraph>
              - Set the default search engine to a preferred one (e.g., Google SafeSearch) and restrict changes via Group Policy or browser settings.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* macOS Setup Section */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Setup on macOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1" gutterBottom>
              Step 1: Install BlockWebAI Extension
            </Typography>
            <Typography variant="body1" paragraph>
              Open the browser on your child's Mac and install the BlockWebAI extension from the browser's extension store.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 2: Disable Private Browsing
            </Typography>
            <Typography variant="body1" paragraph>
              <strong>For Safari:</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              - Open the Terminal application.
            </Typography>
            <Typography variant="body1" paragraph>
              - Enter the following command to disable private browsing:
            </Typography>
            <Typography variant="body2" paragraph>
              <code>defaults write com.apple.Safari IncludePrivateBrowsing -bool false</code>
            </Typography>
            <Typography variant="body1" paragraph>
              - Use parental controls to enforce this setting.
            </Typography>

            <Typography variant="body1" paragraph>
              <strong>For Chrome/Firefox:</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              - Use third-party tools like Parental Controls or MDM solutions to restrict private browsing modes.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 3: Restrict Alternate Profiles
            </Typography>
            <Typography variant="body1" paragraph>
              - Set up a standard user account for your child without administrative privileges.
            </Typography>
            <Typography variant="body1" paragraph>
              - Use parental controls to restrict changes to browser settings and prevent the creation of new profiles.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 4: Prevent Extension Uninstallation
            </Typography>
            <Typography variant="body1" paragraph>
              - Utilize Mobile Device Management (MDM) tools like Apple Configurator to enforce extension installation.
            </Typography>
            <Typography variant="body1" paragraph>
              - Configure profiles to lock extension settings and prevent removal.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 5: Restrict Other Browsers and Search Engines
            </Typography>
            <Typography variant="body1" paragraph>
              - Use parental controls to limit access to other browsers.
            </Typography>
            <Typography variant="body1" paragraph>
              - Set the default search engine in browser settings and restrict changes using profiles or parental controls.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* iOS Setup Section */}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Setup on iOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="subtitle1" gutterBottom>
              Step 1: Install BlockWebAI App and Extension
            </Typography>
            <Typography variant="body1" paragraph>
              - Download the BlockWebAI app from the App Store on your child's iOS device.
            </Typography>
            <Typography variant="body1" paragraph>
              - Open <strong>Settings</strong>, scroll down to <strong>Safari</strong>, and tap on <strong>Content Blockers</strong>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Enable the BlockWebAI content blocker.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 2: Disable Private Browsing
            </Typography>
            <Typography variant="body1" paragraph>
              - Go to <strong>Settings &gt; Screen Time &gt; Content & Privacy Restrictions</strong>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Enable <strong>Content & Privacy Restrictions</strong> and set a Screen Time passcode.
            </Typography>
            <Typography variant="body1" paragraph>
              - Under <strong>Content Restrictions</strong>, tap on <strong>Web Content</strong> and select <strong>Limit Adult Websites</strong> or <strong>Allowed Websites Only</strong>.
            </Typography>
            <Typography variant="body1" paragraph>
              - This will disable private browsing in Safari.

            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 3: Restrict Alternate Browsers
            </Typography>
            <Typography variant="body1" paragraph>
              - In <strong>Allowed Apps</strong>, disable other browsers by toggling them off.
            </Typography>
            <Typography variant="body1" paragraph>
              - To prevent new app installations, set <strong>Installing Apps</strong> to <strong>Don't Allow</strong> under <strong>iTunes & App Store Purchases</strong>.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 4: Prevent Uninstalling Apps
            </Typography>
            <Typography variant="body1" paragraph>
              - In <strong>Content & Privacy Restrictions</strong>, go to <strong>iTunes & App Store Purchases</strong>.
            </Typography>
            <Typography variant="body1" paragraph>
              - Set <strong>Deleting Apps</strong> to <strong>Don't Allow</strong>.
            </Typography>
            <Typography variant="body1" paragraph>
              - This prevents your child from uninstalling the BlockWebAI app.
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Step 5: Restrict Search Engines
            </Typography>
            <Typography variant="body1" paragraph>
              - In <strong>Settings &gt; Safari &gt; Search Engine</strong>, select your preferred search engine.
            </Typography>
            <Typography variant="body1" paragraph>
              - In <strong>Content Restrictions</strong>, under <strong>Web Content</strong>, restrict access to explicit content.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Addressing Windows Flagging Issue */}
        <Box sx={{ mt: 4, p: 3, bgcolor: 'warning.main', color: 'warning.contrastText', borderRadius: 2 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <LightbulbIcon sx={{ mr: 1 }} /> Resolving Windows Security Warnings
          </Typography>
          <Typography variant="body1" paragraph>
            When downloading the installer, Windows Defender SmartScreen might flag the file as unsafe because it is from an unknown publisher. To proceed with the installation:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="After downloading, double-click the installer." />
            </ListItem>
            <ListItem>
              <ListItemText primary='If you see a "Windows protected your PC" message, click on "More info".' />
            </ListItem>
            <ListItem>
              <ListItemText primary='Click the "Run anyway" button to start the installer.' />
            </ListItem>
          </List>
          <Typography variant="body1">
            Rest assured, the BlockWebAI installer is safe. We are working on getting a digital signature to prevent these warnings in the future.
          </Typography>
        </Box>

        {/* Additional Tips */}
        <Box sx={{ mt: 4, p: 3, bgcolor: 'secondary.main', color: 'secondary.contrastText', borderRadius: 2 }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <LightbulbIcon sx={{ mr: 1 }} /> Additional Tips
          </Typography>
          <Typography variant="body1">
            Regularly review your child's browsing activity and update the BlockWebAI settings to adapt to their changing needs. Open communication about internet safety is also crucial.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default SetupGuide;
