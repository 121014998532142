import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VisibilitySensor from 'react-visibility-sensor';

const DemoSection = styled.div`
  background: white;
  color: #333;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  text-align: center;
`;

const DemoHeading = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
`;

const DemoDescription = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin-bottom: 2rem;
`;

const UserInput = styled.div`
  border: 1px solid #333;
  padding: 1rem;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  min-width: 300px;
`;

const TypingText = styled.span`
  &::after {
    content: '|';
    animation: blink 1s steps(2, start) infinite;
  }

  @keyframes blink {
    to { visibility: hidden; }
  }
`;

function Demo() {
  const [typedText, setTypedText] = useState('');
  const [startTyping, setStartTyping] = useState(false);
  const currentTypingIndex = useRef(0);
  const demoUrls = [
    { url: 'educationalgames.com', allowed: true },
    { url: 'socialmedia.com', allowed: false },
    { url: 'onlinelearning.org', allowed: true },
    { url: 'videogames.net', allowed: false }
  ];
  const userPrompt = 'Allow learning content, block distractions';

  const simulateWebAccess = () => {
    demoUrls.forEach((item, index) => {
      setTimeout(() => {
        if (item.allowed) {
          toast.success(`Allowed: ${item.url}`, {
            icon: "✅",
            style: { background: "#e6f7e6", color: "#2e7d32" }
          });
        } else {
          toast.error(`Blocked: ${item.url}`, {
            icon: "🚫",
            style: { background: "#fde8e8", color: "#c62828" }
          });
        }
      }, index * 2000);
    });
  };

  useEffect(() => {
    let typingTimer;
    if (startTyping) {
      typingTimer = setInterval(() => {
        if (currentTypingIndex.current < userPrompt.length) {
          setTypedText((prevText) => prevText + userPrompt[currentTypingIndex.current]);
          currentTypingIndex.current++;
        } else {
          clearInterval(typingTimer);
          simulateWebAccess();
        }
      }, 100);
    }
    return () => clearInterval(typingTimer);
  }, [startTyping]);

  return (
    <DemoSection id="demo">
      <ToastContainer />
      <DemoHeading>How BlockWebAI Works</DemoHeading>
      <DemoDescription>
        BlockWebAI uses advanced artificial intelligence to understand your natural language instructions 
        and create custom, real-time content filters. Whether you want to "allow only educational content" 
        or "block specific topics," BlockWebAI adapts to your needs, ensuring a safer, smarter internet 
        experience that grows with your family.
      </DemoDescription>
      <VisibilitySensor
        partialVisibility
        onChange={(isVisible) => {
          if (isVisible) setStartTyping(true);
        }}
      >
        <UserInput>
          <TypingText>{typedText}</TypingText>
        </UserInput>
      </VisibilitySensor>
    </DemoSection>
  );
}

export default Demo;