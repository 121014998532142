// Login.js
import React, { useContext, useState } from 'react';
import { UserContext } from './UserContext';
import { loginUser, getUser } from './apiService';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, TextField, Paper, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

function Login() {
  const { setUser } = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const Navigate = useNavigate();

  const handleLogin = async () => {
    const tokenResponse = await loginUser(username, password);
    if (tokenResponse) {
      const token = tokenResponse.access_token;
      console.log(token)
      // Store the token and user in local storage
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify({ username }));
      // Set the token as the default authorization header
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      // Set the user in the context
      const updatedUser = await getUser();
      // print each key-value pair
      Object.keys(updatedUser).forEach(function(key) {
        console.log(key, updatedUser[key]);
      });
      setUser(updatedUser);
      Navigate('/'); // navigate to the dashboard
      window.location.reload();
    } else {
      // Set the error message
      setErrorMessage('Invalid username or password');

    }
  };
  
  
  

  return (
    <Paper style={{ padding: '2rem', width: '300px', margin: '0 auto' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Login
      </Typography>
      <TextField 
        type="text"
        label="Username"
        variant="outlined"
        value={username} 
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <TextField 
        type="password"
        label="Password"
        variant="outlined"
        value={password} 
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Button variant="contained" color="primary" onClick={handleLogin} fullWidth>
        Login
      </Button>
      <Typography variant="body1" align="center" style={{ marginTop: '1rem' }}>
        Don't have an account? <Link to="/register">Register</Link>
      </Typography>
    </Paper>
  );
}

export default Login;
