//PromptList.js
import React from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Draggable } from 'react-beautiful-dnd';
import { TextField } from '@mui/material';


function PromptList({ prompts, darkMode, handleDeletePrompt, editingPromptId, editingText, setEditingPromptId, setEditingText, handleUpdatePrompt, handlePromptClick, selectedPromptId }) {
  return prompts.map((prompt, index) => (
    <Draggable key={prompt._id} draggableId={String(prompt._id)} index={index}>
      {(provided, snapshot) => (
        <div style={{position: 'relative'}}>
          <IconButton 
            style={{ 
              position: 'absolute', 
              right: 10,  /* Adjusted to bring the trash can closer to the prompt */
              top: 0, 
              color: darkMode ? 'white' : 'black', 
              zIndex: 1 
            }}
            onClick={() => handleDeletePrompt(prompt._id)}
          >
            <DeleteIcon />
          </IconButton>
          <Card
            className={`card ${darkMode ? 'dark-theme' : 'light-theme'} ${snapshot.isDragging ? 'is-dragging' : ''} ${prompt._id === selectedPromptId ? 'is-selected' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            key={prompt._id}
          >
            <div
              {...provided.dragHandleProps}
              style={{
                cursor: 'move',
                padding: '10px'
              }}
              onClick={() => handlePromptClick(prompt._id, prompt.text)}
            >
              <CardContent>
                {editingPromptId === prompt._id ? (
                  <form onSubmit={(e) => handleUpdatePrompt(prompt._id, prompt.order, e)}>
                    <TextField
                      value={editingText}
                      onChange={(e) => setEditingText(e.target.value)}
                      label="Edit Prompt"
                      variant="outlined"
                      autoFocus
                      fullWidth
                    />
                  </form>
                ) : (
                  <Typography 
                    style={{ cursor: 'pointer' }}
                    onDoubleClick={() => { setEditingPromptId(prompt._id); setEditingText(prompt.text); }}
                  >
                    {prompt.text}
                  </Typography>
                )}
              </CardContent>
            </div>
          </Card>
        </div>                  
      )}
    </Draggable>
  ));
}

export default PromptList;
