// UserContext.js
import { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const userItem = localStorage.getItem('user');
  const localUserData = userItem ? JSON.parse(userItem) : null;
  const [user, setUser] = useState(localUserData);

  const saveUser = (userData) => {
    // save user data to local storage
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  return (
    <UserContext.Provider value={{ user, setUser: saveUser }}>
      {children}
    </UserContext.Provider>
  );
};
