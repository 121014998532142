import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Paper,
  createTheme,
  ThemeProvider,
  Switch,
  FormControlLabel,
  Link,
  Button,
} from '@mui/material';

function Downloads() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#bb86fc' : '#6200ee',
      },
      secondary: {
        main: darkMode ? '#03dac6' : '#03dac6',
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
          <Typography variant="h3" gutterBottom>
            Downloads
          </Typography>
          <FormControlLabel
            control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
            label={darkMode ? 'Dark Mode' : 'Light Mode'}
          />
        </Box>

        <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            Get BlockWebAI
          </Typography>
          <Typography variant="body1" paragraph>
            Download the latest versions of BlockWebAI for your devices.
          </Typography>
        </Paper>

        {/* Windows Installer */}
        <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            BlockWebAI for Windows
          </Typography>
          <Typography variant="body1" paragraph>
            Install BlockWebAI on your Windows device using our automatic installer.
          </Typography>
          <Box textAlign="center" mt={2}>
            <Link href="/BlockWebAI_Setup.exe" underline="none">
              <Button variant="contained" color="primary" size="large">
                Download Windows Installer
              </Button>
            </Link>
          </Box>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            Note: Windows Defender might flag the installer as unsafe. Please see the{' '}
            <Link href="/setup" color="inherit">
              setup guide
            </Link>{' '}
            for instructions on how to proceed.
          </Typography>
        </Paper>

        {/* Chrome Extension */}
        <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            BlockWebAI Chrome Extension
          </Typography>
          <Typography variant="body1" paragraph>
            Install the BlockWebAI extension on Google Chrome.
          </Typography>
          <Box textAlign="center" mt={2}>
            <Link
              href="https://chrome.google.com/webstore/detail/blockwebai/jgfdcafnckmhficckccgcniebflbiimi"
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              <Button variant="contained" color="primary" size="large">
                Get Chrome Extension
              </Button>
            </Link>
          </Box>
        </Paper>

        {/* Future Additions */}
        <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Coming Soon
          </Typography>
          <Typography variant="body1" paragraph>
            We are working on bringing BlockWebAI to more platforms. Stay tuned!
          </Typography>
        </Paper>

        {/* Additional Information */}
        <Box sx={{ mt: 4, p: 3, bgcolor: 'secondary.main', color: 'secondary.contrastText', borderRadius: 2 }}>
          <Typography variant="body1">
            For setup instructions and more information, please visit our{' '}
            <Link href="/setup-guide" color="inherit">
              Setup Guide
            </Link>
            .
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Need help? Our FAQ section is coming soon. You can also contact our support team for assistance.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Downloads;
