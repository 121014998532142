// PaymentPage.js 
import React, { useContext, useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Grid, Paper, createTheme, ThemeProvider, Table, TableBody, TableCell, TableContainer, TableRow, TableHead } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PayPalButton } from 'react-paypal-button-v2';
import { useStripe } from "@stripe/react-stripe-js";
import premiumIcon from './images/premium_icon.png'; // some icon indicating premium features
import { CssBaseline } from '@mui/material';
import { updateSubscription } from './apiService.js';  // Make sure to import the function
import { UserContext } from './UserContext';

//import './Dashboard/Dashboard.css';

// Load Stripe with your publishable key. Make sure to replace with your live publishable key in production!
const stripePromise = loadStripe('pk_test_yourPublishableKey'); // Still need to get my stripe key
const StripeCheckoutButton = ({ priceId, subscriptionType }) => {
  const stripe = useStripe();
  const { setUser } = useContext(UserContext);

  const handleClick = async (event) => {
    try {
      event.preventDefault();
      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: priceId, quantity: 1 }],
        mode: 'subscription',
        successUrl: 'https://your-website.com/success',
        cancelUrl: 'https://your-website.com/canceled',
      });
  
    } catch (error) {
      if (error) {
        console.log(`subscriptionType: ${subscriptionType}`); // here rn since it always errors for local dev //TODO CHANGE FOR SERVER Change this stuff when running on server, since error catching is just for local dev
        const [updatedUser] = await updateSubscription(subscriptionType);
        console.log(updatedUser);  
        if (updatedUser) {
          setUser(updatedUser);
        } else {
          console.log("Error updating subscription, user not updated")
        }
        
        
        
        
        
        
        console.warn('Error:', error);
      } else {
        const updatedUser = await updateSubscription(subscriptionType);
        const updatedUser_current = updatedUser.current_user;
        setUser(updatedUser_current);
      }
    }
  };
  

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Subscribe
    </Button>
  );
};


function PaymentPage() {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);
  console.log(`darkMode: ${darkMode}`)
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#ffffff' : '#000000', // Change as needed
      },
      secondary: {
        main: darkMode ? '#aaaaaa' : '#333333', // Change as needed
      },
    },
  });
  
  
return (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <div style={{ padding: '0 5%' }}>
      <Grid container spacing={3} style={{ paddingTop: '5%' }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" color="textPrimary" gutterBottom style={{ paddingTop: '5%' }}>
            Upgrade to Premium
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph>
            Unlock the full potential of BlockWebAI with our different plans.
          </Typography>
          <TableContainer 
  component={Paper} 
  style={{ 
    backgroundColor: darkMode ? null : '#1876D1', 
    color: darkMode ? 'white' : 'black' 
  }}
>
  <Table>
    <TableHead>
      <TableRow>
        {['', 'Free', '$3/month', '$10/month'].map((plan, index) => (
          <TableCell
            key={index}
            style={{
              fontSize: '1.5rem',
              color: darkMode ? 'white' : 'white',
              borderBottom: '1px solid ' + (darkMode ? 'white' : 'black'),
            }}
            align={index === 0 ? 'left' : 'center'}
          >
            {plan}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        {['Daily Scans', '75', '300', 'Unlimited'].map(
          (scans, index) => (
            <TableCell
              key={index}
              style={{ fontSize: '1.1rem', color: darkMode ? 'white' : 'white' }}
              align={index === 0 ? 'left' : 'center'}
            >
              {scans}
            </TableCell>
          )
        )}
      </TableRow>
      <TableRow>
        {['Features', '-', 'Audit with improved model', 'Audit with GPT-4'].map((feature, index) => (
          <TableCell
            key={index}
            style={{ fontSize: '1.1rem', color: darkMode ? 'white' : 'white' }}
            align={index === 0 ? 'left' : 'center'}
          >
            {feature}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
      {['Upgrade', '-', 
        <Elements key="3-dollar-plan" stripe={stripePromise}><StripeCheckoutButton priceId="price_your3DollarPlanId" subscriptionType="standard" /></Elements>, 
        <Elements key="10-dollar-plan" stripe={stripePromise}><StripeCheckoutButton priceId="price_your10DollarPlanId" subscriptionType="premium" /></Elements>        
      ].map((upgrade, index) => (
        <TableCell
          key={index}
          style={{ fontSize: '1.1rem', color: darkMode ? 'white' : 'white' }}
          align={index === 0 ? 'left' : 'center'}
        >
          {upgrade}
        </TableCell>
      ))}
    </TableRow>
    </TableBody>
  </Table>
</TableContainer>


        </Grid>
        <Grid item xs={12} md={6}>
          <img src={premiumIcon} alt="premiumIcon" style={{ width: '100%', height: 'auto' }} />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '5%', marginBottom: '5%' }}>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '2rem'}}>
            
            <Typography variant="h6" color="textPrimary" gutterBottom>
              More AI Runs
            </Typography>
            <Typography variant="body1" color="textSecondary">
              With a premium subscription, get 20x as many AI runs (2000 instead of 100) per day checking websites to keep you on task.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '2rem' }}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Multiple Profiles
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Set up multiple profiles with unique blacklists, whitelists, and prompts that can be switched in a single click.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '2rem' }}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              Prioritized Support
            </Typography>
            <Typography variant="body1" color="textSecondary">
              Premium users get priority for support requests and new feature releases.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <footer style={{ textAlign: 'center', padding: '3rem 0', background: darkMode ? '#333' : '#f4f4f4' }}>
        <Typography variant="h6" color="textPrimary" gutterBottom>
          BlockWebAI
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Making the web a better place, one tab at a time.
        </Typography>
      </footer>
    </div>
  </ThemeProvider>
);
}

export default PaymentPage;