import React from 'react';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { BsChevronCompactDown } from 'react-icons/bs';
import { Link as ScrollLink } from 'react-scroll';
import Particles from 'particles-bg';
import DemoSection from './Demo';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const Container = styled.div`
  display: grid;
  place-items: center;
  min-height: 100vh;
  background: linear-gradient(to right, #1a2a6c, #12c2e9, #c471ed, #f64f59);
  color: white;
  text-align: center;
  padding: 2rem;
  position: relative;
`;

const fade = keyframes`
  0% { color: #1a2a6c; }
  25% { color: #12c2e9; }
  50% { color: #c471ed; }
  75% { color: #f64f59; }
  100% { color: #1a2a6c; }
`;

const Heading = styled.h1`
  font-size: 4rem;
  margin-bottom: 2rem;
  z-index: 1;
`;

const Subheading = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  color: white;
  z-index: 1;
`;

const Description = styled.p`
  font-size: 1.2rem;
  max-width: 800px;
  margin-bottom: 2rem;
  z-index: 1;
`;

const AnimatedButton = styled(animated.button)`
  padding: 1rem 2rem;
  font-size: 1.2rem;
  background: white;
  color: #1a2a6c;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease-in-out;
  animation: pulse 2s infinite;
  z-index: 1;

  &:hover {
    background: #f8f9fa;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const ScrollIcon = styled(BsChevronCompactDown)`
  font-size: 2rem;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  color: white;
  z-index: 1;

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
`;

const ScrollInstruction = styled.p`
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  animation: fadeInSlideUp 2s ease-out forwards;
  opacity: 0;
  color: white;
  z-index: 1;

  @keyframes fadeInSlideUp {
    0% {
      opacity: 0;
      transform: translate(-50%, 50px);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
`;

const ContainerContent = styled(animated.div)`
  animation: slideUp 2s ease-out forwards;
  opacity: 0;

  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const ScrollContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 100%;
`;

function LandingPage() {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 }
  });

  return (
    <>
      <GlobalStyle />
      <Container>
        <Particles type="cobweb" bg={true} />
        <ContainerContent style={springProps}>
          <Heading>BlockWebAI</Heading>
          <Subheading>A web filter made just for you</Subheading>
          <Description>
            Use AI-powered content filtering to create a safer, smarter internet experience for your children.
          </Description>
          <Link to="/register">
            <AnimatedButton>Get Started</AnimatedButton>
          </Link>
        </ContainerContent>
        <ScrollContainer>
          <ScrollInstruction>Scroll to learn more</ScrollInstruction>
          <ScrollLink to="demo" smooth={true} duration={500}>
            <ScrollIcon />
          </ScrollLink>
        </ScrollContainer>
      </Container>
      <DemoSection />
    </>
  );
}

export default LandingPage;