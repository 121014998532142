// WhiteList.js
import React from 'react';
import { Card, CardContent, Typography, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Draggable } from 'react-beautiful-dnd';

function WhiteList({ whitelist, darkMode, handleRemoveFromWhitelist, editingWhitelistId, editingWhitelistUrl, setEditingWhitelistId, setEditingWhitelistUrl, handleUpdateWhitelist }) {
  return whitelist.map((url, index) => (
    <Draggable key={url._id} draggableId={String(url._id)} index={index}>
      {(provided, snapshot) => (
        <div style={{position: 'relative'}}>
          <IconButton 
           style={{ 
            position: 'absolute', 
            right: 10,  /* Adjusted to bring the trash can closer to the prompt */
            top: 0, 
            color: darkMode ? 'white' : 'black', 
            zIndex: 1 
          }}
            onClick={() => handleRemoveFromWhitelist(url._id)}
          >
            <DeleteIcon />
          </IconButton>
          <Card
            className={`card ${darkMode ? 'dark-theme' : 'light-theme'} ${snapshot.isDragging ? 'is-dragging' : ''}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            key={url._id}
          >
            <div
              {...provided.dragHandleProps}
              style={{
                cursor: 'move',
                padding: '10px',
              }}
            >
              <CardContent>
                {editingWhitelistId === url._id ? (
                  <form onSubmit={(e) => handleUpdateWhitelist(url._id, e)}>
                    <TextField
                      value={editingWhitelistUrl}
                      onChange={(e) => setEditingWhitelistUrl(e.target.value)}
                      label="Edit URL"
                      variant="outlined"
                      autoFocus
                      fullWidth
                    />
                  </form>
                ) : (
                  <Typography 
                    style={{ cursor: 'pointer' }}
                    onDoubleClick={() => { setEditingWhitelistId(url._id); setEditingWhitelistUrl(url.url); }}
                  >
                    {url.url}
                  </Typography>
                )}
              </CardContent>
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  ));
}

export default WhiteList;
