// App.js
import React, { useContext } from 'react';
import axios from 'axios';
import LandingPage from './LandingPage';
import Login from './Login';
import Register from './Register'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppBar, Button, Toolbar, Typography, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import PaymentPage from './PaymentPage';
import Dashboard from './Dashboard/Dashboard';
import { UserContext } from './UserContext';
import favicon from './images/favicon.png'; 
import InfoPage from './InfoPage';
import SetupGuide from './SetupGuide';
import Downloads from './Downloads';
import Contact from './Contact';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  const { user, setUser } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  
  const handleClose = () => {
    setOpenDialog(false);
  };
  
  const handleLogout = () => {
    handleClose();
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    delete axios.defaults.headers.common['Authorization'];
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <Router> 
        <AppBar position="static">
          <Toolbar>
            <Link to="/" style={{ textDecoration: 'none', color: 'white', flexGrow: 1 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={favicon} alt="BlockWebAI Logo" style={{ marginRight: '1em' }}/>
                <Typography variant="h6">
                  BlockWebAI
                </Typography>
              </div>
            </Link>
            {!user ? (
              <>
                <Button color="inherit" component={Link} to="/login">Login</Button>
                <Button color="inherit" component={Link} to="/register">Register</Button>
                <Button color="inherit" component={Link} to="/info">Info</Button>
                <Button color="inherit" component={Link} to="/setup">Setup Guide</Button>
                <Button color="inherit" component={Link} to="/downloads">Downloads</Button>
                <Button color="inherit" component={Link} to="/contact">Contact Us</Button>

              </>
            ) : (
              <>
                <Button color="inherit" component={Link} to="/info">Info</Button>
                <Button color="inherit" component={Link} to="/setup">Setup Guide</Button>
                <Button color="inherit" component={Link} to="/downloads">Downloads</Button>
                <Button color="inherit" component={Link} to="/payment">Subscribe</Button>
                <Button color="inherit" component={Link} to="/contact">Contact Us</Button>
                <Button color="inherit" aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick}>Profile</Button>
                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleMenuClose}>Email: {user.email}</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Username: {user.username}</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Subscription: {user.subscription ? user.subscription.name : 'None'}</MenuItem>
                  <MenuItem onClick={handleClickOpen}>Logout</MenuItem>
                </Menu>
                <Dialog
                  open={openDialog}
                  onClose={handleClose}
                >
                  <DialogTitle>Logout</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to logout?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button onClick={handleLogout}>
                      Logout
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/info" element={<InfoPage />} />
          <Route path="/setup" element={<SetupGuide />} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          {!user ? (
            <Route path="/" element={<LandingPage />} />
          ) : (
            <Route path="/" element={<Dashboard />} />
          )}
        </Routes>
    </Router>
  );
}

export default App;
