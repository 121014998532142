
// Register.js
import React, { useContext, useState } from 'react';
import { UserContext } from './UserContext';
import { Link } from 'react-router-dom';
import { registerUser, loginUser } from './apiService';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, TextField, Paper, Typography } from '@mui/material';

function Register() {
  const { setUser } = useContext(UserContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  const handleRegister = async () => {
    const user = await registerUser(username, email, password);
    if (user !== null) {
      const tokenResponse = await loginUser(username, password);
      const token = tokenResponse.access_token;
      // Store the token in local storage for persistence
      localStorage.setItem('token', token);
      // Set the token as the default authorization header
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser({ username, subscription: user.subscription });  // include subscription in user context
      navigate('/'); // navigate to the dashboard
      window.location.reload() 
    }
  };
  


  return (
    <Paper style={{ padding: '2rem', width:'300px', margin: '0 auto' }}>
      <Typography variant="h4" align="center" gutterBottom>
        Register
      </Typography>
      <TextField 
        type="text"
        label="Username"
        variant="outlined"
        value={username} 
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <TextField 
        type="email"
        label="Email"
        variant="outlined"
        value={email} 
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <TextField 
        type="password"
        label="Password"
        variant="outlined"
        value={password} 
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <Button variant="contained" color="primary" onClick={handleRegister} fullWidth>
        Register
      </Button>
      <Typography variant="body1" align="center" style={{ marginTop: '1rem' }}>
        Already have an account? <Link to="/login">Login</Link>
      </Typography>
    </Paper>
  );
}

export default Register;
