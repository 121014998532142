
// Dashboard.js
import React, { useState, useRef, useEffect, createContext, useContext, useCallback } from 'react';
import { getProfiles, createProfile, getProfileData, updateProfileData, getUniversalData, updateUniversalData } from '../apiService';
import { getPrompts, createPrompt, updatePrompt, setCurrentPrompt, getCurrentPrompt, deletePrompt, getWhitelist, getBlacklist, addToWhitelist, addToBlacklist, removeFromWhitelist, removeFromBlacklist, updateBlacklist, updateWhitelist,  addWhitelistBulk, addBlacklistBulk } from '../apiService';
import { Button, TextField, Container, Card, CardContent, Typography, Tooltip, Switch, IconButton, Box } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserContext } from '../UserContext';
import { makeStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import { animated, useSpring } from 'react-spring';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import axios from 'axios';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Pagination from '@mui/material/Pagination';

import './Dashboard.css';

import PromptList from './PromptList';
import WhiteList from './WhiteList';
import BlackList from './BlackList';

function useAccordion(defaultOpen = false) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleOpen = useCallback(() => {
    setIsOpen((open) => !open);
  }, []);

  return [isOpen, toggleOpen];
}

const AccordionContext = createContext();

function AccordionProvider({ children }) {
  const [floatingButtonAccordion, setFloatingButtonAccordion] = useState(null);

  const floatButton = useCallback((id) => {
    setFloatingButtonAccordion(id);
  }, []);

  return (
    <AccordionContext.Provider value={{ floatButton, floatingButtonAccordion }}>
      {children}
    </AccordionContext.Provider>
  );
}


function AccordionSection({ id, title, isOpen, toggleOpen, children, animationsEnabled}) {

  const { floatButton, floatingButtonAccordion } = useContext(AccordionContext);
  const isFloating = floatingButtonAccordion === id;
  const sectionRef = useRef(null);
  const buttonRef = useRef(null);
  const openButtonRef = useRef(null);
  const [buttonPosition, setButtonPosition] = useState({top: 0, left: 0});
  const [animationId, setAnimationId] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);


  const handleButtonClick = (event) => {
    event.stopPropagation();
    if (isAnimating) {
      // If an animation is ongoing, ignore the button click
      return;
    }
    toggleOpen();
  };

  

  const smoothScrollTo = (targetY, duration) => {
    if (!animationsEnabled) {
      // If animations are disabled, jump directly to the target
      //window.scrollTo(0, targetY); // or maybe this should be not scrolling at all? <CHECK THIS> 
      return;
    }  
    setIsAnimating(true);    const startingY = window.pageYOffset;
    const diff = targetY - startingY;
    const startTimestamp = performance.now();

    const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

    const step = (timestamp) => {
      const elapsed = timestamp - startTimestamp;
      const progress = Math.min(elapsed / duration, 1);
      window.scrollTo(0, startingY + diff * easeInOutQuad(progress));
      if (elapsed < duration) {
        setAnimationId(window.requestAnimationFrame(step));
      } else {
        setAnimationId(null);  // Reset the animation ID when the animation is done
        setIsAnimating(false);
      }
      }

    setAnimationId(window.requestAnimationFrame(step));
  };

  useEffect(() => {
    if (isOpen && sectionRef.current && animationsEnabled) {
      setTimeout(() => {
        const rect = buttonRef.current.getBoundingClientRect();
        floatButton(isOpen ? id : null);  // float button if section is opened
        smoothScrollTo(sectionRef.current.offsetTop - 50, 500);
      }, 1);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen && animationsEnabled) {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [isOpen]);
  


  useEffect(() => {

    if (isOpen && sectionRef.current && animationsEnabled) {
      // Wait for the animation to finish then scroll into view
      setTimeout(() => {
        // Calculate the position to scroll to (add an offset to the current position)
        const availableSpace = window.innerHeight;
        const sectionHeight = sectionRef.current.offsetHeight;
  
        let positionToScrollTo = sectionRef.current.offsetTop - 50; // Adjust the offset value as needed
        if (sectionHeight < availableSpace) {
          // If the section is bigger than the viewport, scroll to the top of the section
          positionToScrollTo = sectionRef.current.offsetTop - 50;
        } else {
          // If the section fits in the viewport, scroll so that the bottom of the section aligns with the bottom of the viewport
          positionToScrollTo = sectionRef.current.offsetTop + sectionHeight - availableSpace + 35;
        }
        // Save the position of the collapse button
        const rect = buttonRef.current.getBoundingClientRect();
        setButtonPosition({
          top: rect.top + window.pageYOffset,
          left: rect.left + window.pageXOffset,
        });
        smoothScrollTo(positionToScrollTo, 500);
  
      }, 1);  // delay in ms, adjust based on your animation duration
    }
    else if (!isOpen && buttonRef.current) {
      // Use the starting position when animations are disabled
      setButtonPosition({
        top: 0,
        left: 0
      });
    }
  }, [isOpen, animationsEnabled]);
  

  const springProps = useSpring({
    maxHeight: isOpen ? '1200px' : '0px',
    config: animationsEnabled ? { tension: 600, friction: 55 } : { tension: 0, friction: 0 },
    overflow: 'hidden',
  });


  
  return (
    <div className="section-container" ref={sectionRef}>
      <Typography variant="h5" className="dashboard-section-title" onClick={toggleOpen} style={{ position: 'relative' }}>
        {title}
        <div className="icon-container" ref={buttonRef} style={{ position: 'absolute', right: 0 }}>
          {isOpen ? (
            <IconButton
              className="collapse-button"
              style={{
                position: animationsEnabled && isFloating ? 'fixed' : 'absolute',
                top: animationsEnabled ? buttonPosition.top : buttonPosition.top - 20,  // subtract 20 pixels only when animations are disabled
                right: animationsEnabled ? window.innerWidth - buttonPosition.left : 0,
                zIndex: 9999
              }}
              onClick={handleButtonClick}
            >
              <ExpandLessIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton
            className="collapse-button"
            style={{ position: 'absolute', right: 0, top: -20}}
            onClick={handleButtonClick}
            ref={openButtonRef}
          >
            <ExpandMoreIcon fontSize="large" />
          </IconButton>
          
          )}
        </div>
      </Typography>

      <animated.div style={springProps}>
        {children}
      </animated.div>
    </div>
  );
}

function Dashboard() {
  const [promptsOpen, togglePromptsOpen] = useAccordion(true);
  const [whitelistOpen, toggleWhitelistOpen] = useAccordion();
  const [blacklistOpen, toggleBlacklistOpen] = useAccordion();
  const [animationsEnabled, setAnimationsEnabled] = useState(() => {
    const saved = localStorage.getItem('animationsEnabled');
    return saved !== null ? JSON.parse(saved) : true; // default to true if not in localStorage
  });
  const { user, setUser } = useContext(UserContext); // idk if we neeed this

  const token = localStorage.getItem('token');

  const [prompts, setPrompts] = useState([]);
  const [whitelist, setWhitelist] = useState([]);
  const [blacklist, setBlacklist] = useState([]);
  const [newPromptText, setNewPromptText] = useState('');
  const [newWhitelistUrl, setNewWhitelistUrl] = useState('');
  const [newBlacklistUrl, setNewBlacklistUrl] = useState('');
  const [editingWhitelistId, setEditingWhitelistId] = useState(null);
  const [editingWhitelistUrl, setEditingWhitelistUrl] = useState('');
  const [editingBlacklistId, setEditingBlacklistId] = useState(null);
  const [editingBlacklistUrl, setEditingBlacklistUrl] = useState('');
  const [editingPromptId, setEditingPromptId] = useState(null);
  const [editingText, setEditingText] = useState('');
  const [selectedPromptId, setSelectedPromptId] = useState(null);
  const whitelistFileInput = useRef(null);
  const blacklistFileInput = useRef(null);

  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [universalData, setUniversalData] = useState({ prompts: [], whitelist: [], blacklist: [] });
 
  


    const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  useEffect(() => {
    localStorage.setItem('animationsEnabled', JSON.parse(animationsEnabled));
  }, [animationsEnabled]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchedProfiles = await getProfiles();
      setProfiles(fetchedProfiles);
      setSelectedProfile(null) 
      //if (fetchedProfiles.length > 0) {
       // console.log(`Setting selected profile to ${fetchedProfiles[0]._id}`);
        //setSelectedProfile(fetchedProfiles[0]._id);
    //} else {
     //   setSelectedProfile(null);  // or handle it appropriately
    //}    
      const fetchedUniversalData = await getUniversalData();
      setUniversalData(fetchedUniversalData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (selectedProfile) {
        console.log(selectedProfile);
        const profileData = await getProfileData(selectedProfile);
        setPrompts(profileData.prompts || []);
        setWhitelist(profileData.whitelist || []);
        setBlacklist(profileData.blacklist || []);
      } else {
        // Handle the case where there's no selected profile (perhaps load universal data or show a default state)
        setPrompts([]);
        setWhitelist([]);
        setBlacklist([]);
      }
    };
    fetchProfileData();
  }, [selectedProfile]);
  
  

  const handleProfileChange = (event) => {
    console.log(`Selected profile: ${event.target.value}`);
    setSelectedProfile(event.target.value);
  };

  const handleCreateProfile = async () => {
    const profileName = prompt("Enter new profile name:");
    if (profileName) {
      const newProfile = await createProfile(profileName);
      setProfiles([...profiles, newProfile]);
      setSelectedProfile(newProfile._id);
    }
  };

  const handleUpdateData = async (type, data) => {
    if (selectedProfile) {
      await updateProfileData(selectedProfile, 
        type === 'prompts' ? data : prompts,
        type === 'whitelist' ? data : whitelist,
        type === 'blacklist' ? data : blacklist
      );
    } else {
      await updateUniversalData(
        type === 'prompts' ? data : universalData.prompts,
        type === 'whitelist' ? data : universalData.whitelist,
        type === 'blacklist' ? data : universalData.blacklist
      );
    }
  };

  const handleUpdateUniversalData = async (updatedData) => {
    try {
      await updateUniversalData(updatedData);
      setUniversalData(updatedData);
    } catch (error) {
      console.error('Failed to update universal data:', error);
    }
  };

  const [currentPromptPage, setCurrentPromptPage] = useState(1);
  const itemsPerPage = 6; // Or however many you want
  const handlePromptPageChange = (event, value) => {
    setCurrentPromptPage(value);
  };

  const [currentWhitelistPage, setCurrentWhitelistPage] = useState(1);
  const handleWhitelistPageChange = (event, value) => {
    setCurrentWhitelistPage(value);
  };

  const [currentBlacklistPage, setCurrentBlacklistPage] = useState(1);
  const handleBlacklistPageChange = (event, value) => {
    setCurrentBlacklistPage(value);
  };

  const currentPrompts = prompts.slice((currentPromptPage - 1) * itemsPerPage, currentPromptPage * itemsPerPage);
  const currentWhitelist = whitelist.slice((currentWhitelistPage - 1) * itemsPerPage, currentWhitelistPage * itemsPerPage);
  const currentBlacklist = blacklist.slice((currentBlacklistPage - 1) * itemsPerPage, currentBlacklistPage * itemsPerPage);

  const [stagingArea, setStagingArea] = useState([]);



  const handlePromptClick = async (id, text) => {
    setSelectedPromptId(id);
    await setCurrentPrompt(id, text);
  };
  

  const sortedPrompts = [...prompts].sort((a, b) => {
    if (a._id === selectedPromptId) {
      return -1;
    }
    if (b._id === selectedPromptId) {
      return 1;
    }
    return 0;
  });

  const handleAddPrompt = async (e) => {
    e.preventDefault();
    const newPrompt = { text: newPromptText, id: Date.now(), order: prompts.length + 1 };
    
    if (selectedProfile) {
      const updatedPrompts = [...prompts, newPrompt];
      setPrompts(updatedPrompts);
      await handleUpdateData('prompts', updatedPrompts);
    } else {
      // Updating universal data
      const updatedUniversalData = {
        ...universalData,
        prompts: [...universalData.prompts, newPrompt]
      };
      await handleUpdateUniversalData(updatedUniversalData);
    }
    
    setNewPromptText('');
  };
  const handleUpdatePrompt = async (id, order, e) => {
    e.preventDefault();
    if (editingText) {
      if (selectedProfile) {
        const updatedPrompts = prompts.map(prompt => 
          prompt._id === id ? { ...prompt, text: editingText, order: order } : prompt
        );
        setPrompts(updatedPrompts);
        await handleUpdateData('prompts', updatedPrompts);
      } else {
        // Updating universal data
        const updatedUniversalData = {
          ...universalData,
          prompts: universalData.prompts.map(prompt => 
            prompt._id === id ? { ...prompt, text: editingText, order: order } : prompt
          )
        };
        await handleUpdateUniversalData(updatedUniversalData);
      }
      setEditingPromptId(null);
      setEditingText('');
    }
  };
  
  const handleDeletePrompt = async (id) => {
    try {
      await deletePrompt(id);
      if (selectedProfile) {
        const updatedPrompts = prompts.filter(prompt => prompt._id !== id);
        setPrompts(updatedPrompts);
        // Remove this line: await handleUpdateData('prompts', updatedPrompts);
      } else {
        // Updating universal data
        const updatedUniversalData = {
          ...universalData,
          prompts: universalData.prompts.filter(prompt => prompt._id !== id)
        };
        setUniversalData(updatedUniversalData);
        // Remove this line: await handleUpdateUniversalData(updatedUniversalData);
      }
    } catch (error) {
      console.error('Failed to delete prompt:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  const handleAddToWhitelist = async (e) => {
    e.preventDefault(); // Prevent form submission
    try {
      if (newWhitelistUrl === '') {
        return;
      }
      const savedUrl = await addToWhitelist(newWhitelistUrl);
      setWhitelist(prevWhitelist => [...prevWhitelist, {...savedUrl}]);
      setNewWhitelistUrl('');
    } catch (error) {
      console.error('Failed to add URL to whitelist:', error);
    }
  };

  const handleWhitelistFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const urls = text.split('\n').map(line => ({ url: line.trim() })).filter(item => item.url);
        try {
          await addWhitelistBulk(urls);
          // Refresh the whitelist
          const whitelist = await getWhitelist();
          setWhitelist(whitelist);
        } catch (error) {
          console.error('Failed to upload URLs:', error);
        }
      };
      reader.readAsText(file);
    }
  };
  
  const handleBlacklistFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const urls = text.split('\n').map(line => ({ url: line.trim() })).filter(item => item.url);
        try {
          await addBlacklistBulk(urls);
          // Refresh the blacklist
          const blacklist = await getBlacklist();
          setBlacklist(blacklist);
        } catch (error) {
          console.error('Failed to upload URLs:', error);
        }
      };
      reader.readAsText(file);
    }
  };


  

  const handleAddToBlacklist = async (e) => {
    e.preventDefault(); // Prevent form submission
    try {
      if (newBlacklistUrl === '') {
        return;
      }
      const savedUrl = await addToBlacklist(newBlacklistUrl);
      setBlacklist(prevBlacklist => [...prevBlacklist, {...savedUrl}]);
      setNewBlacklistUrl('');
    } catch (error) {
      console.error('Failed to add URL to blacklist:', error);
    }
  };
  

  const handleRemoveFromWhitelist = async (id) => {
    try {
      console.log(`Removing id: ${id}`); // Log the id
      await removeFromWhitelist(id);
      setWhitelist(whitelist.filter(url => url._id !== id));
    } catch (error) {
      console.error('Failed to remove URL from whitelist:', error);
    }
  };

  const handleRemoveFromBlacklist = async (id) => {
    try {
      await removeFromBlacklist(id);
      setBlacklist(blacklist.filter(url => url._id !== id));
    } catch (error) {
      console.error('Failed to remove URL from blacklist:', error);
    }
  };
  const handleUpdateWhitelist = async (id, e) => {
    e.preventDefault();
    if (editingWhitelistUrl) {
      const oldWhitelist = [...whitelist];
      const updatedItem = whitelist.find(item => item._id === id);
      const updatedOrder = updatedItem ? updatedItem.order : 0;  // Default to 0 if not found
      setWhitelist(whitelist.map(item => item._id === id ? { ...item, url: editingWhitelistUrl } : item));
      setEditingWhitelistId(null);
      setEditingWhitelistUrl('');
      try {
        await updateWhitelist(id, editingWhitelistUrl, updatedOrder);
      } catch (error) {
        setWhitelist(oldWhitelist);
      }
    }
  };
  

  const handleUpdateBlacklist = async (id, e) => {
    e.preventDefault();
    if (editingBlacklistUrl) {
      const oldBlacklist = [...blacklist];
      const updatedItem = blacklist.find(item => item._id === id);
      const updatedOrder = updatedItem ? updatedItem.order : 0;  // Default to 0 if not found
      setBlacklist(blacklist.map(item => item._id === id ? { ...item, url: editingBlacklistUrl } : item));
      setEditingBlacklistId(null);
      setEditingBlacklistUrl('');
      try {
        await updateBlacklist(id, editingBlacklistUrl, updatedOrder);
      } catch (error) {
        setBlacklist(oldBlacklist);
      }
    }
  };

  const handleOnDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) return;

    if (destination.droppableId === 'stagingArea') {
      // Handle adding to the staging area
      if (type === 'prompts') {
        const prompt = prompts.find(prompt => prompt._id === draggableId);
        setStagingArea([...stagingArea, prompt]);
        setPrompts(prompts.filter(prompt => prompt._id !== draggableId));
      } else if (type === 'whitelist') {
        const url = whitelist.find(url => url._id === draggableId);
        setStagingArea([...stagingArea, url]);
        setWhitelist(whitelist.filter(url => url._id !== draggableId));
      } else if (type === 'blacklist') {
        const url = blacklist.find(url => url._id === draggableId);
        setStagingArea([...stagingArea, url]);
        setBlacklist(blacklist.filter(url => url._id !== draggableId));
      }
    } else if (source.droppableId === 'stagingArea') {
      // Handle removing from the staging area
      if (type === 'prompts') {
        const prompt = stagingArea.find(prompt => prompt._id === draggableId);
        setPrompts([...prompts, prompt]);
        setStagingArea(stagingArea.filter(prompt => prompt._id !== draggableId));
      } else if (type === 'whitelist') {
        const url = stagingArea.find(url => url._id === draggableId);
        setWhitelist([...whitelist, url]);
        setStagingArea(stagingArea.filter(url => url._id !== draggableId));
      } else if (type === 'blacklist') {
        const url = stagingArea.find(url => url._id === draggableId);
        setBlacklist([...blacklist, url]);
        setStagingArea(stagingArea.filter(url => url._id !== draggableId));
      }
    } else if (type === 'whitelist') {
      const items = Array.from(whitelist);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
  
      setWhitelist(items);
      for (const [index, item] of items.entries()) {
        try {
          await updateWhitelist(item._id, item.url, index);
        } catch (error) {
          console.error('Failed to update order of whitelist:', error);
        }
      }
    } else if (type === 'blacklist') {
      const items = Array.from(blacklist);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);
  
      setBlacklist(items);
      for (const [index, item] of items.entries()) {
        try {
          await updateBlacklist(item._id, item.url, index);
        } catch (error) {
          console.error('Failed to update order of blacklist:', error);
        }
      }
    }
  };
  

const theme = darkMode ? 'dark-theme' : 'light-theme';
document.documentElement.className = theme;

  const not_logged_in = localStorage.getItem('notLoggedIn') 
  return (
    <Container className={`dashboard-container ${theme}`}>
      <div className="profile-selector">
        <select value={selectedProfile} onChange={handleProfileChange}>
          <option value="">Universal</option>
          {profiles.map(profile => (
            <option key={profile.id} value={profile._id}>{profile.profile_name}</option>
          ))}
        </select>
        <button onClick={handleCreateProfile}>Create New Profile</button>
      </div>
      <div className="dashboard-darkmode-switch">
        <Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
        <Typography>{darkMode ? 'Dark Mode' : 'Light Mode'}</Typography>
        <Switch checked={animationsEnabled} onChange={() => setAnimationsEnabled(!animationsEnabled)}/>
        <Typography>{!animationsEnabled ? 'Animations Disabled' : 'Animations Enabled'}</Typography>
      </div>
      <AccordionProvider>
        <AccordionSection title="Prompts 📖" id="prompts" isOpen={promptsOpen} toggleOpen={togglePromptsOpen} animationsEnabled={animationsEnabled}>
          <div className="section-container">
            <form onSubmit={handleAddPrompt} className={`dashboard-form ${theme}`}>
              <TextField
                value={newPromptText}
                onChange={(e) => setNewPromptText(e.target.value)}
                label="Add to Prompts"
                variant="outlined"
                className="dashboard-input"
                fullWidth
                InputProps={{
                  style: {
                    color: darkMode ? 'white' : 'black',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddPrompt(e);
                        }}
                        style={{ color: darkMode ? 'white' : 'grey' }}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    color: darkMode ? 'white' : 'black',
                  },
                }}
              />
            </form>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="prompts" type="prompts">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <PromptList 
                      prompts={selectedProfile ? currentPrompts : universalData.prompts}
                      darkMode={darkMode}
                      handleDeletePrompt={handleDeletePrompt}
                      editingPromptId={editingPromptId}
                      editingText={editingText}
                      setEditingPromptId={setEditingPromptId}
                      setEditingText={setEditingText}
                      handleUpdatePrompt={handleUpdatePrompt}
                      handlePromptClick={handlePromptClick}
                      selectedPromptId={selectedPromptId}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Pagination count={Math.ceil(prompts.length / itemsPerPage)} page={currentPromptPage} onChange={handlePromptPageChange} />
          </div>
        </AccordionSection>
        <AccordionSection title="Whitelist ⬜️" id="whitelist" isOpen={whitelistOpen} toggleOpen={toggleWhitelistOpen} animationsEnabled={animationsEnabled}>
          <div className="section-container">
            <form onSubmit={handleAddToWhitelist} className="dashboard-form">
              <TextField
                value={newWhitelistUrl}
                onChange={(e) => setNewWhitelistUrl(e.target.value)}
                label="Add to Whitelist"
                variant="outlined"
                className="dashboard-input"
                fullWidth
                InputProps={{
                  style: {
                    color: darkMode ? 'white' : 'black',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddToWhitelist(e);
                        }}
                        style={{ color: darkMode ? 'white' : 'grey' }}
                        >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  color: darkMode ? 'white' : 'black',
                },
              }}
              />
              <input
                type="file"
                accept=".csv,.txt"
                onChange={handleWhitelistFileUpload}
                style={{ display: "none" }}
                ref={whitelistFileInput}
              />
    
              <IconButton
                color="primary"
                component="span"
                onClick={() => whitelistFileInput.current.click()}
              >
                <AttachFileIcon />
                <input
                  type="file"
                  accept=".csv,.txt"
                  onChange={handleWhitelistFileUpload}
                  style={{ display: "none" }}
                  ref={whitelistFileInput}
                />
              </IconButton>
            
          </form>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="whitelist" type="whitelist">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                   <WhiteList
                    whitelist={selectedProfile ? currentWhitelist : universalData.whitelist}
                    darkMode={darkMode}
                    handleRemoveFromWhitelist={handleRemoveFromWhitelist}
                    editingWhitelistId={editingWhitelistId}
                    editingWhitelistUrl={editingWhitelistUrl}
                    setEditingWhitelistId={setEditingWhitelistId}
                    setEditingWhitelistUrl={setEditingWhitelistUrl}
                    handleUpdateWhitelist={handleUpdateWhitelist}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Pagination count={Math.ceil(whitelist.length / itemsPerPage)} page={currentWhitelistPage} onChange={handleWhitelistPageChange} />
          </div>
        </AccordionSection>
        <AccordionSection title="Blacklist ⬛️" id="blacklist" isOpen={blacklistOpen} toggleOpen={toggleBlacklistOpen} animationsEnabled={animationsEnabled}>
          <div className="section-container">
            <form onSubmit={handleAddToBlacklist} className="dashboard-form">
              <TextField
                value={newBlacklistUrl}
                onChange={(e) => setNewBlacklistUrl(e.target.value)}
                label="Add to Blacklist"
                variant="outlined"
                className="dashboard-input"
                fullWidth
                InputProps={{
                  style: {
                    color: darkMode ? 'white' : 'black',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={(e) => {
                          e.preventDefault();
                          handleAddToBlacklist(e);
                        }}
                        style={{ color: darkMode ? 'white' : 'grey' }}
                      >
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    color: darkMode ? 'white' : 'black',
                  },
                }}
              />
                <input
            type="file"
            accept=".csv,.txt"
            onChange={handleBlacklistFileUpload}
            style={{ display: "none" }}
            ref={blacklistFileInput}
          />
          <IconButton
            color="primary"
            component="span"
            onClick={() => blacklistFileInput.current.click()}
          >
            <AttachFileIcon />
            <input
              type="file"
              accept=".csv,.txt"
              onChange={handleBlacklistFileUpload}
              style={{ display: "none" }}
              ref={blacklistFileInput}
            />
          </IconButton>  </form>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="blacklist" type="blacklist">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <BlackList
                    blacklist={selectedProfile ? currentBlacklist : universalData.blacklist}
                    darkMode={darkMode}
                    handleRemoveFromBlacklist={handleRemoveFromBlacklist}
                    editingBlacklistId={editingBlacklistId}
                    editingBlacklistUrl={editingBlacklistUrl}
                    setEditingBlacklistId={setEditingBlacklistId}
                    setEditingBlacklistUrl={setEditingBlacklistUrl}
                    handleUpdateBlacklist={handleUpdateBlacklist}
                  />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Pagination count={Math.ceil(blacklist.length / itemsPerPage)} page={currentBlacklistPage} onChange={handleBlacklistPageChange} />
          </div>
          </AccordionSection>
    </AccordionProvider>
  </Container>
);

}

export default Dashboard;
                     
  