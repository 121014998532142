// apiService.js
import axios from 'axios';

//const API_URL = "https://blockweb-ai-parents-5ac5c7b49cf2.herokuapp.com";

const API_URL = process.env.NODE_ENV === 'development'
  ? "http://192.168.1.180:5000"
  : "https://blockweb-ai-parents-5ac5c7b49cf2.herokuapp.com";

async function apiCall(method, url, data = null, customHeaders = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true',
    ...customHeaders
  };

  try {
    const response = await axios({
      method,
      url: `${API_URL}${url}`,
      data,
      headers
    });
    return response.data;
  } catch (error) {
    console.error(error.response?.data?.detail || error.message);
    throw error;
  }
}

export async function getPrompts() {
  return apiCall('get', '/prompts');
}

export async function createPrompt(text, order) {
  return apiCall('post', '/prompts', { text, order });
}

export async function deletePrompt(id) {
  return apiCall('delete', `/prompts/${id}`);
}


export async function setCurrentPrompt(id, text) {
  return apiCall('post', '/current-prompt', { id, text });
}

export async function getCurrentPrompt() {
  return apiCall('get', '/current-prompt');
}


export async function getProfiles() {
  return apiCall('get', '/user-profiles');
}

export async function createProfile(profileName) {
  return apiCall('post', '/create-profile', { profile_name: profileName });
}

export async function getProfileData(profileId) {
  return apiCall('get', `/profile-data/${profileId}`);
}

export async function updateProfileData(profileId, prompts, whitelist, blacklist) {
  return apiCall('put', `/profile-data/${profileId}`, { prompts, whitelist, blacklist });
}

export async function getUniversalData() {
  return apiCall('get', '/universal-data');
}

export async function updateUniversalData(data) {
  return apiCall('put', '/universal-data', data);
}

export async function getWhitelist() {
  return apiCall('get', '/whitelist');
}

export async function addToWhitelist(url) {
  return apiCall('post', '/whitelist', { url });
}

export async function removeFromWhitelist(id) {
  return apiCall('delete', `/whitelist/${id}`);
}

export async function getBlacklist() {
  return apiCall('get', '/blacklist');
}

export async function addToBlacklist(url) {
  return apiCall('post', '/blacklist', { url });
}

export async function removeFromBlacklist(id) {
  return apiCall('delete', `/blacklist/${id}`);
}

export async function updatePrompt(id, text, order) {
  return apiCall('put', `/prompts/${id}`, { text, order });
}

export async function updateWhitelist(id, url, order) {
  return apiCall('put', `/whitelist/${id}`, { url, order });
}

export async function updateBlacklist(id, url, order) {
  return apiCall('put', `/blacklist/${id}`, { url, order });
}

export async function registerUser(username, email, password) {
  return apiCall('post', '/users/', { username, email, hashed_password: password });
}

export async function loginUser(username, password) {
  try {
    const response = await axios.post(`${API_URL}/token`, 
      `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    if (response.data) {
      localStorage.setItem('token', response.data.access_token);
      return response.data;
    } else {
      throw new Error("No data received from server");
    }
  } catch (error) {
    console.error(error.response?.data?.detail || error.message);
    throw error;
  }
}

export async function getUser() {
  return apiCall('get', '/users/me');
}

export async function addWhitelistBulk(urls) {
  return apiCall('post', '/whitelist/bulk', urls);
}

export async function addBlacklistBulk(urls) {
  return apiCall('post', '/blacklist/bulk', urls);
}

export async function sendContactForm(name, email, message) {
  return apiCall('post', '/contact', { name, email, message });
}

export async function getRunsRemaining() {
  return apiCall('get', '/runs-remaining');
}

export async function updateSubscription(subscription_type) {
  return apiCall('post', '/set-subscription', { subscription_type });
}