import React from 'react';
import { Typography, Container, Box, Accordion, AccordionSummary, AccordionDetails, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import SecurityIcon from '@mui/icons-material/Security';
import PolicyIcon from '@mui/icons-material/Policy';
import ContactMailIcon from '@mui/icons-material/ContactMail';

function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ bgcolor: '#f9f9f9', p: 3, borderRadius: 2 }}>
      <Box my={4} textAlign="center">
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy for BlockWebAI
        </Typography>
        <Typography variant="body1" paragraph>
          Effective Date: September 26th, 2024
        </Typography>
        <Divider />
      </Box>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <LockIcon sx={{ mr: 1 }} />
          <Typography variant="h5">Introduction</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            BlockWebAI ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information when you use our BlockWebAI browser extension ("the Extension").
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <InfoIcon sx={{ mr: 1 }} />
          <Typography variant="h5">Information We Collect</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            The Extension collects the following types of information:
          </Typography>
          <ul>
            <li>Website Content: We collect and analyze the content of web pages you visit to determine if they should be blocked or allowed based on your settings.</li>
            <li>Browsing History: We may collect information about the websites you visit to provide content filtering services and potentially offer detailed browsing reports to parent accounts in the future.</li>
            <li>User Account Information: If you create an account, we collect your email address and username.</li>
            <li>Extension Settings: We store your preferences and settings for the Extension.</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
          <SecurityIcon sx={{ mr: 1 }} />
          <Typography variant="h5">How We Use Your Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We use the collected information for the following purposes:
          </Typography>
          <ul>
            <li>To provide and maintain the core functionality of the Extension, including content filtering and website blocking.</li>
            <li>To improve and personalize your experience with the Extension.</li>
            <li>To develop new features, such as detailed browsing reports for parent accounts.</li>
            <li>To communicate with you about your account and provide customer support.</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
          <PolicyIcon sx={{ mr: 1 }} />
          <Typography variant="h5">Data Sharing and Disclosure</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We do not sell your personal information. We may share your information in the following circumstances:
          </Typography>
          <ul>
            <li>With your consent.</li>
            <li>To comply with legal obligations.</li>
            <li>To protect our rights, privacy, safety, or property.</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
          <SecurityIcon sx={{ mr: 1 }} />
          <Typography variant="h5">Data Retention & Security</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            We retain your data for as long as necessary to provide our services and comply with legal obligations. You can request deletion of your data by contacting us.
          </Typography>
          <Typography variant="body1" paragraph>
            We implement reasonable security measures to protect your information. However, no method of transmission over the Internet or electronic storage is 100% secure.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
          <ContactMailIcon sx={{ mr: 1 }} />
          <Typography variant="h5">Your Rights & Contact Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Depending on your location, you may have rights regarding your personal information, such as the right to access, correct, or delete your data. Contact us to exercise these rights.
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, please contact us at jonathanlarsonbusiness@gmail.com.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box mt={4}>
        <Typography variant="caption" display="block" gutterBottom>
          This Privacy Policy is subject to change. Any updates will be posted on this page.
        </Typography>
      </Box>
    </Container>
  );
}

export default PrivacyPolicy;
